<template>
  <v-container fluid pa-0 ma-0>
    <v-card>
      <v-card-title>Exportar a CSV <v-spacer></v-spacer> 🤓</v-card-title>
      <v-card-subtitle>Descarga todos los resgistros en formato csv</v-card-subtitle>
      <v-card-text>
        <v-row dense justify="center" align="center">
          <v-col  md="6">
            <h3>Total de registros:</h3>
            <h1 class="text-xl font-weight-semibold primary--text my-2">3456 <span class="title">Tests</span></h1>
          </v-col>
          <v-col class="text-right">
            <v-btn x-large elevation="0" color="primary">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'ExportCSV',
  data: () => ({
    //
  }),
  //
  methods: {
    //
  }
}
</script>
