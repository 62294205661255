<template>
  <v-container fluid>
    <v-row >
      <v-col><LastList /></v-col>
      <!-- <v-col></v-col> -->
      <v-col cols="12" md="4">
        <ExportCsv />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <LastList />
      </v-col>
    </v-row> -->

  </v-container>
</template>


<script>
import LastList from '@/components/dashboard/LastList'
import ExportCsv from '@/components/dashboard/ExportCsv'
export default {
  name: 'Dashboard',
  data: () => ({
    //
  }),
  methods: {
    //
  },
  components: {
    LastList, ExportCsv
  }
}
</script>